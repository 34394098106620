














import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private data: any;
  @Watch("data", { immediate: true })
  private dataChange() {
    this.detail = JSON.parse(JSON.stringify(this.data["关联文件"]));
    this.$forceUpdate();
  }
  private detail: any;
  private preview(url: any) {
    window.open(url);
  }
  private delImg(item: any, index: any) {
    this.$forceUpdate();
    this.$emit("delImg", item);
  }
}
