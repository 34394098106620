



















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private ifShow: any;
  @Watch("ifShow", { immediate: true })
  private ifShowChange() {
    this.ifShowSaveConfirm = this.ifShow;
  }
  private ifShowSaveConfirm: any = false;
  private cancel() {
    this.$emit("cancel");
  }
  private leave() {
    this.$emit("leave");
  }
}
