var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tree"},[_c('div',{staticClass:"top-btn"},[_c('el-select',{attrs:{"placeholder":"全部状态","size":"mini","disabled":_vm.ifCompose,"clearable":""},on:{"change":_vm.statusChange},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('el-option',{attrs:{"label":"待校验","value":"待校验"}}),_c('el-option',{attrs:{"label":"已打回","value":"已打回"}}),_c('el-option',{attrs:{"label":"待审核","value":"待审核"}}),_c('el-option',{attrs:{"label":"已通过","value":"已通过"}})],1),(_vm.ifShow('新增目录') && !_vm.status)?_c('img',{attrs:{"src":"/common/add2.png","alt":""},on:{"click":function($event){return _vm.append({})}}}):_vm._e()],1),(_vm.status)?[(_vm.searchTreeData.length > 0)?_c('div',{staticClass:"tree-list"},_vm._l((_vm.searchTreeData),function(item){return _c('div',{key:item._id,class:{ active: _vm.currentNode._id == item._id },on:{"click":function($event){return _vm.clickSearchNav(item)}}},[_c('span',[_vm._v(" "+_vm._s(item["章节名"]))]),_c('span',{staticStyle:{"color":"#970404"}},[_vm._v("("+_vm._s(item.status)+")")]),(item.status == '已通过')?_c('span',[_vm._v("("+_vm._s(item["字数"])+"字)")]):_vm._e()])}),0):_c('div',[_c('Empty')],1)]:[_c('el-tree',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.titles && _vm.data.titles.length > 0),expression:"data.titles && data.titles.length > 0"}],ref:"tree",attrs:{"data":_vm.data.titles,"draggable":"","check-on-click-node":"","highlight-current":"","node-key":"_id","props":_vm.defaultProps,"expand-on-click-node":true,"default-expanded-keys":_vm.expandedNode},on:{"node-drop":_vm.handleDrop},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('span',{staticClass:"custom-tree-node"},[_c('div',{staticStyle:{"display":"flex","align-items":"center"},on:{"click":function($event){return _vm.handleNodeClick(data)}}},[_c('span',{staticClass:"oneline",attrs:{"title":node.label}},[_vm._v(_vm._s(node.label))]),_c('span',{staticStyle:{"color":"#970404"}},[_vm._v("("+_vm._s(data.status)+")")]),(data.status == '已通过')?_c('span',[_vm._v("("+_vm._s(data["字数"])+"字)")]):_vm._e()]),(_vm.currentNode && _vm.currentNode['_id'] == data['_id'])?_c('div',{staticClass:"rightBtn"},[(_vm.ifShow('新增子目录', data))?_c('img',{staticClass:"addBtn",attrs:{"src":"/common/add2.png","alt":""},on:{"click":function($event){return _vm.append(data)}}}):_vm._e(),(_vm.ifShow('删除', data))?_c('el-dropdown',{on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link"},[_c('img',{staticClass:"moreBtn",attrs:{"src":"/common/more.png","alt":""}})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":{
                  data: data,
                  node: node,
                  command: '重命名',
                }}},[_vm._v("重命名")]),_c('el-dropdown-item',{attrs:{"command":{
                  data: data,
                  node: node,
                  command: '删除',
                }}},[_vm._v("删除")])],1)],1):_vm._e()],1):_vm._e()])}}])}),(this.data.length == 0)?_c('div',{staticClass:"noTree"},[_vm._v(" 暂无目录，请点击右上角 "),_c('img',{attrs:{"src":"/common/add2.png","alt":""}}),_vm._v(" 按钮添加 ")]):_vm._e()],_c('el-dialog',{staticClass:"commonDialog",attrs:{"width":"500px","visible":_vm.ifShowAdd},on:{"update:visible":function($event){_vm.ifShowAdd=$event}}},[_c('button',{staticClass:"close",on:{"click":function($event){_vm.ifShowAdd = false}}}),_c('div',{staticClass:"header"},[_c('span',{staticClass:"title"},[_vm._v("添加目录")])]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"cell"},[_c('div',{staticClass:"label"},[_vm._v("目录名称：")]),_c('div',{staticClass:"cell-content"},[_c('el-input',{attrs:{"size":"medium","placeholder":"请输入目录名称"},model:{value:(_vm.modifyData['章节名']),callback:function ($$v) {_vm.$set(_vm.modifyData, '章节名', $$v)},expression:"modifyData['章节名']"}})],1)])]),_c('div',{staticClass:"control"},[_c('button',{staticClass:"cancelBtn",on:{"click":function($event){_vm.ifShowAdd = false}}},[_vm._v("取消")]),_c('button',{staticClass:"confirmBtn",on:{"click":_vm.addSure}},[_vm._v("确定")])])]),_c('SaveConfirm',{attrs:{"ifShow":_vm.ifShowSaveConfirm},on:{"cancel":function($event){_vm.ifShowSaveConfirm = false},"leave":function($event){return _vm.leave()}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }