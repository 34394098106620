












































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  GetDaohang,
  AddDaohang,
  EditDaohang,
  DelDaohang,
  SortDaohang,
  GetZhuantis,
} from "@/request/schema";
import {
  GetBookMulu,
  GetBookMulu2,
  DropChapter,
  EditChapter,
  DelChapter,
} from "@/request/storehouse";
import SaveConfirm from "@/components/saveConfirm.vue";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    SaveConfirm,
    Empty,
  },
})
export default class Name extends Vue {
  @Prop()
  private page: any;
  @Prop()
  private detail: any;
  private id: any = "";
  private currentNode: any = {};
  private expandedNode: any = [];
  private data: any = [];
  private searchTreeData: any = [];
  private defaultProps: any = {
    label: "章节名",
  };
  private addJiegouData: any = {};
  private modifyData: any = {};
  private ifShowAdd: any = false;
  private clickData: any = {}; // 点击的数据
  private ifShowSaveConfirm: any = false;
  private status: any = "";
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  private leave() {
    this.$store.commit("updateIfCompose", false);
    this.ifShowSaveConfirm = false;
    if (this.status) {
      this.clickSearchNav(this.clickData);
    } else {
      this.handleNodeClick(this.clickData);
    }
  }
  /**
   * @description 判断按钮是否显示
   */
  private ifShow(type: any, data?: any) {
    let result: any = false;
    const btnType: any = type; // 按钮类型
    const page: any = this.page; // 父页面，原文校验、原文审核、原文切分
    const bookStatus: any = this.data["校验状态"]; // 书的状态
    let titleStatus: any = ""; // 章节状态
    if (data) {
      titleStatus = data.status;
    }
    if (btnType == "新增目录") {
      if (
        page == "原文校验" &&
        (bookStatus == "待校验" || bookStatus == "校验中")
      ) {
        result = true;
      }
    } else if (page != "原文切分" && btnType == "新增子目录") {
      if (titleStatus == "待校验") {
        result = true;
      }
    } else if (btnType == "删除") {
      if (page == "原文校验") {
        if (titleStatus == "待校验") {
          result = true;
        }
      }
      if (page == "原文审核") {
        if (titleStatus != "已通过") {
          result = true;
        }
      }
    }
    return result;
  }
  private clickSearchNav(item: any) {
    if (this.ifCompose) {
      this.clickData = item;
      this.ifShowSaveConfirm = true;
    } else {
      this.currentNode = item;
      this.$emit("refresh", this.currentNode);
    }
  }
  private statusChange() {
    if (this.status) {
      this.currentNode = {};
      this.getSearchNav().then((d: any) => {
        this.searchTreeData = d;
        //如果有符合条件的，默认选中第一个
        if (d.length > 0) {
          this.currentNode = d[0];
        }
        this.$emit("refresh", this.currentNode);
      });
    } else {
      this.init();
    }
  }
  private getSearchNav() {
    return new Promise((resolve, reject) => {
      const params: any = {
        params: {
          book_id: this.id,
          status: this.status,
        },
      };
      GetBookMulu2(this, params)
        .then((res: any) => {
          let d = res;
          if (d.length > 0) {
            d.forEach((ele: any) => {
              ele.status = ele["校验状态"];
            });
          }
          resolve(d);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  }
  private add(data: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      type: "语义类型",
      name: data.name,
    };
    AddDaohang(this, params, loading).then((res: any) => {
      this.$message.success("添加成功！");
      loading.close();
      this.getNav();
    });
  }
  private addSure() {
    if (!this.modifyData["章节名"]) {
      this.$message.warning("请输入目录名称！");
      return;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    EditChapter(this, this.modifyData, loading).then((res: any) => {
      loading.close();
      this.ifShowAdd = false;
      if (this.modifyData.title_id) {
        this.$message.success("重命名成功！");
        this.getNav(true);
      } else {
        this.$message.success("添加成功！");
        this.getNav();
      }
    });
  }
  private append(data: any) {
    if (!data._id) {
      this.modifyData = {
        book_id: this.id,
        章节名: "",
      };
    } else {
      this.modifyData = {
        book_id: this.id,
        parent_id: this.detail.title_id,
        章节名: "",
      };
    }
    this.ifShowAdd = true;
  }
  private handleCommand(e: any) {
    if (e.command == "重命名") {
      this.modifyData = this.detail;
      this.ifShowAdd = true;
    } else if (e.command == "删除") {
      this.$confirm(
        "您确定要删除该目录么？删除后该目录下的子结构也将全部删除。",
        "删除",
        {
          customClass: "commonConfirm",
        }
      )
        .then(() => {
          const params: any = {
            data: {
              title_id: e.data._id,
            },
          };
          const loading = this.$loading({
            lock: true,
            text: "加载中……",
            spinner: "el-icon-loading",
            customClass: "loadClass",
            background: "rgba(255, 255, 255, 0.7)",
          });
          DelChapter(this, params, loading).then((res: any) => {
            loading.close();
            this.$message.success("删除成功！");
            if (e.data._id == this.currentNode._id) {
              this.currentNode = {};
            }
            this.getNav();
          });
        })
        .catch(() => {
          console.log("取消");
        });
    }
  }
  // 拖拽成功完成时触发的事件
  private handleDrop(draggingNode: any, dropNode: any, dropType: any, ev: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      book_id: this.id,
      titles: this.data.titles,
    };
    DropChapter(this, params, loading).then((res: any) => {
      loading.close();
      this.$message.success("移动成功！");
      this.getNav();
    });
  }
  private handleNodeClick(data: any) {
    if (this.ifCompose) {
      this.clickData = data;
      this.ifShowSaveConfirm = true;
    } else {
      this.currentNode = data;
      this.init();
    }
  }
  private init() {
    setTimeout(() => {
      if (this.$refs.tree) {
        (this.$refs.tree as any).setCurrentKey(this.currentNode);
        this.expandedNode = [this.currentNode["_id"]];
        this.data.titles.forEach((ele: any) => {
          if (ele._id == this.currentNode._id) {
            this.currentNode = ele;
          }
        });
        this.$emit("refresh", this.currentNode);
      }
    }, 100);
  }
  private getNav(ifChange?: any) {
    return new Promise((resolve, reject) => {
      const loading = this.$loading({
        lock: true,
        text: "加载中……",
        spinner: "el-icon-loading",
        customClass: "loadClass",
        background: "rgba(255, 255, 255, 0.7)",
      });
      if (this.status) {
        console.log("获取导航数据");
        console.log(JSON.parse(JSON.stringify(this.currentNode)));
        this.getSearchNav().then((d: any) => {
          console.log(d);
          this.searchTreeData = d;
          if (d.length > 0) {
            let obj: any = {};
            d.forEach((ele: any) => {
              if (ele._id == this.currentNode._id) {
                obj = ele;
              }
            });
            if (obj._id) {
              this.currentNode = obj;
            } else {
              this.currentNode = d[0];
            }
          }
          this.$emit("refresh", this.currentNode);
        });
      } else {
        const params: any = {
          params: {
            book_id: this.id,
            need_status: true,
          },
        };
        GetBookMulu(this, params, loading).then((res: any) => {
          loading.close();
          this.data = res;
          if (this.data.titles && this.data.titles.length > 0) {
            if (!this.currentNode._id) {
              this.currentNode = this.data.titles[0];
              this.init();
            } else {
              if (ifChange) {
                this.data.titles.forEach((ele: any) => {
                  if (ele._id == this.currentNode._id) {
                    this.currentNode = ele;
                  }
                });
              } else {
                this.init();
              }
            }
          }
          resolve(res);
        });
      }
    });
  }
  private mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getNav();
    }
  }
}
