




















































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import { GetRenwuList } from "@/request/mark";
import { GetGujiRenwu } from "@/request/check";
@Component({})
export default class Name extends mixins(listMixin, quanxianMixin) {
  @Prop()
  private id: any;
  @Prop()
  private renwuTime: any;
  @Watch("renwuTime", { immediate: true })
  private renwuTimeChange() {
    this.search();
  }
  private data: any = [];
  /**
   * @description 获取任务列表
   */
  getList() {
    const params: any = {
      params: {
        order_field: "创建时间",
        order_value: this.filters.sort_value,
        current_page: this.filters.current_page,
        page_num: this.filters.page_count,
        book_id: this.id,
      },
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetGujiRenwu(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.data = data.data;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
}
